<h2 mat-dialog-title>Select date</h2>
<mat-dialog-content>
    <mat-form-field>
        <mat-label>Choose a from date</mat-label>
        <input matInput [matDatepicker]="toPicker" [(ngModel)]="data.from">
        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
        <mat-datepicker #toPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Choose a to date</mat-label>
        <input matInput [matDatepicker]="fromPicker" [(ngModel)]="data.to">
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="submit()" color="accent">Select</button>
</mat-dialog-actions>
