<div class="filter-container">
    <div class="filters">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Name</mat-label>
            <input matInput [value]="filters.name || ''" (input)="applyInputFilter('name', $event)"/>
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>IP address</mat-label>
            <input matInput [value]="filters.networks.address || ''"
                   (input)="applyInputFilter('networks.address', $event)"/>
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Assigned</mat-label>
            <mat-select [value]="filters.assigned" (selectionChange)="applyFilterAssigned($event)">
                <mat-option>All</mat-option>
                <mat-option value="true">Yes</mat-option>
                <mat-option value="false">No</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Purpose</mat-label>
            <mat-select multiple [value]="filters.purpose" (selectionChange)="applyFilterPurpose($event)">
                <mat-option value="customer">customer</mat-option>
                <mat-option value="appslot">appslot</mat-option>
                <mat-option value="seedbox">seedbox</mat-option>
                <mat-option value="miscellaneous">miscellaneous</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Term</mat-label>
            <mat-select [value]="filters.contractTerm" (selectionChange)="applyFilterContractTerm($event)">
                <mat-option>All</mat-option>
                <mat-option [value]="1">Month</mat-option>
                <mat-option [value]="3">Quarter</mat-option>
                <mat-option [value]="6">Bi-Annual</mat-option>
                <mat-option [value]="12">Annual</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Provider</mat-label>
            <mat-select [value]="filters.provider" (selectionChange)="applyFilterProvider($event)">
                <mat-option>All</mat-option>
                <mat-option value="Hetzner">Hetzner</mat-option>
                <mat-option value="Leaseweb">Leaseweb</mat-option>
                <mat-option value="Proxmox">Proxmox</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Provider ID</mat-label>
            <input matInput [value]="filters.provider_id || ''" (input)="applyInputFilter('provider_id', $event)"/>
        </mat-form-field>
    </div>
</div>

<div class="list">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100" matSort
           [matSortActive]="pageInfo.sort"
           [matSortDirection]="pageInfo.direction"
           (matSortChange)="announceSortChange($event)">
        <caption style="display: none">Servers</caption>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ID">#</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{ element.displayName }}</td>
        </ng-container>

        <ng-container matColumnDef="server_type">
            <th mat-header-cell *matHeaderCellDef>Provider</th>
            <td mat-cell *matCellDef="let element">{{ element.provider | serverType }}</td>
        </ng-container>

        <ng-container matColumnDef="purpose">
            <th mat-header-cell *matHeaderCellDef>Purpose</th>
            <td mat-cell *matCellDef="let element">{{ element.purpose }}</td>
        </ng-container>

        <ng-container matColumnDef="datacenter">
            <th mat-header-cell *matHeaderCellDef>Datacenter</th>
            <td mat-cell *matCellDef="let element">{{ element?.datacenter?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="assignee_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by assignee">Assigned</th>
            <td mat-cell *matCellDef="let element">{{ element.assignee ? 'Yes' : 'No' }}</td>
        </ng-container>

        <ng-container matColumnDef="contractExpireAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by contract date">
                Contract end date
            </th>
            <td mat-cell *matCellDef="let element">{{ (element.contractExpireAt | date) ?? '-' }}</td>
        </ng-container>

        <ng-container matColumnDef="contractTerm">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by contract term">
                Contract term
            </th>
            <td mat-cell *matCellDef="let element">{{ element.contractTerm | contractTerm }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">{{ element.status | titlecase }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDetails($event, row)"
            (auxclick)="goToDetails($event, row)" class="pointer">
        </tr>
    </table>
</div>

<mat-paginator
    [length]="this.pageInfo.total"
    [pageIndex]="this.pageInfo.number"
    [pageSize]="this.pageInfo.size"
    [pageSizeOptions]="[10, 25, 50, 100]"
    showFirstLastButtons
    (page)="updatePage($event)"
    aria-label="Select page"
    class="mt-2 mat-elevation-z8 paginator"
></mat-paginator>

<button mat-fab color="primary" class="mat-elevation-z8 add-button" (click)="showAddServerDialog()">
    <mat-icon>add</mat-icon>
</button>
