import { ChangeDetectorRef, Component } from '@angular/core';
import { Server } from '../../../models/server';
import { Datastore } from '../../../services/datastore';
import { JsonApiQueryData } from 'mpugach-angular2-jsonapi';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonApiErrorResponse } from '../../../services/json-api-error-response';
import { MatDialog } from '@angular/material/dialog';
import { AddServerDialogComponent } from './components/add-server-dialog/add-server-dialog.component';
import { Sort, SortDirection } from '@angular/material/sort';
import { MatSelectChange } from '@angular/material/select';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-servers',
    templateUrl: './servers.component.html',
    styleUrls: ['./servers.component.scss'],
    standalone: false
})
export class ServersComponent {
    public displayedColumns: string[] = [
        'id',
        'product',
        'server_type',
        'purpose',
        'datacenter',
        'assignee_id',
        'contractExpireAt',
        'contractTerm',
        'status',
    ];

    public dataSource: Server[] = [];
    public pageInfo = {
        number: 0,
        size: 25,
        total: 0,
        sort: '',
        direction: '' as SortDirection,
    };
    public filters = {
        name: undefined,
        networks: {
            address: undefined,
        },
        provider_id: undefined,
        provider: undefined,
        assigned: undefined,
        purpose: undefined,
        contractTerm: undefined,
    }

    private inputFilter = new Subject();

    constructor(
        private readonly datastore: Datastore,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly route: ActivatedRoute,
        private readonly authService: AuthService,
        private readonly changeDetector: ChangeDetectorRef,
    ) {
        this.route.queryParams
            .subscribe(params => {
                if (params.number) {
                    this.pageInfo.number = parseInt(params.number);
                }

                if (params.size) {
                    this.pageInfo.size = parseInt(params.size);
                }

                if (params.sort) {
                    this.pageInfo.sort = params.sort;
                }

                if (params.direction) {
                    this.pageInfo.direction = params.direction;
                }

                if (params.filters) {
                    this.filters = JSON.parse(params.filters);
                }

                this.load();
            })

        this.inputFilter.pipe(
            debounceTime(500)
        ).subscribe(x => this.persist())
    }

    private load(): void {
        this.datastore.findAll(
            Server,
            {
                page: {
                    size: this.pageInfo.size,
                    number: this.pageInfo.number + 1,
                },
                sort: (this.pageInfo.direction === 'desc' ? '-' : '') + this.pageInfo.sort,
                include: 'datacenter,assignee',
                filter: this.filters
            },
        ).subscribe({
            next: (result: JsonApiQueryData<Server>) => {
                this.dataSource = result.getModels();
                this.pageInfo.size = result.getMeta().meta.page.perPage;
                this.pageInfo.number = result.getMeta().meta.page.currentPage - 1;
                this.pageInfo.total = result.getMeta().meta.page.total;
            },
            error: (error: JsonApiErrorResponse) => this.datastore.showError(error)
        });
    }

    public updatePage(event: PageEvent): void {
        this.pageInfo.size = event.pageSize;
        this.pageInfo.number = event.pageIndex;

        this.persist();
        this.load();
    }

    public goToDetails(event, server: Server): void {
        // Only left + middle mouse click.
        if (![0, 1].includes(event.button)) {
            return;
        }

        let url = '/servers/' + server.id;

        if (event.button == 1 || (event.ctrlKey || event.metaKey)) {
            window.open(url);
            return;
        }

        this.router.navigateByUrl(url);
    }

    public showAddServerDialog(): void {
        const dialog = this.dialog.open(AddServerDialogComponent, {
            data: this.datastore.createRecord(Server),
            width: '600px',
        });

        dialog.afterClosed().subscribe(() => this.load());
    }

    public announceSortChange(event: Sort): void {
        this.pageInfo.sort = event.active;
        this.pageInfo.direction = event.direction;

        this.persist();
    }

    applyFilterProvider(event: MatSelectChange) {
        this.filters.provider = event.value;

        this.persist();
    }

    applyFilterAssigned(event: MatSelectChange) {
        this.filters.assigned = event.value;

        this.persist();
    }

    applyFilterPurpose(event: MatSelectChange) {
        this.filters.purpose = event.value;

        this.persist();
    }

    applyFilterContractTerm(event: MatSelectChange) {
        this.filters.contractTerm = event.value;

        this.persist();
    }

    applyInputFilter(filter: string, event: any) {
        let filters = filter.split('.')
        let value = event.target.value.length == 0 ? undefined : event.target.value;

        if (filters.length == 1) {
            this.filters[filters[0]] = value;
        } else if (filters.length == 2) {
            this.filters[filters[0]][filters[1]] = value;
        }

        this.inputFilter.next(null);
    }

    private persist() {
        const filters = JSON.stringify(this.filters);

        const params = {
            size: this.pageInfo.size !== 25 ? this.pageInfo.size : undefined,
            number: this.pageInfo.number !== 0 ? this.pageInfo.number : undefined,
            sort: this.pageInfo.sort !== '' && this.pageInfo.sort !== 'id' ? this.pageInfo.sort : undefined,
            direction: this.pageInfo.direction !== '' ? this.pageInfo.direction : undefined,
            filters: filters !== JSON.stringify({}) ? filters : undefined,
        };

        this.router.navigate(
            ['/servers'],
            {
                queryParams: params,
                queryParamsHandling: 'merge',
            },
        );
    }
}
